import { actions } from '../actions/user.actions';


const INITIAL_STATE = {
  currentUser: null,
  allDistributors: [],
  currentDistributor: null,
  allSubDistributors: [],
  currentSubDistributor: null,
  allDealers: [],
  currentDealer: null
}

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_CURRENT_USER:
      return { ...state, currentUser: data.data }
    case actions.SET_ALL_DISTRIBUTORS:
      return { ...state, allDistributors: data.data }
    case actions.SET_CURRENT_DISTRIBUTOR:
      return { ...state, currentDistributor: data.data }
    case actions.SET_ALL_SUB_DISTRIBUTORS:
      return { ...state, allSubDistributors: data.data }
    case actions.SET_CURRENT_SUB_DISTRIBUTOR:
      return { ...state, currentSubDistributor: data.data }
    case actions.SET_ALL_DEALERS:
      return { ...state, allDealers: data.data }
    case actions.SET_CURRENT_DEALER:
      return { ...state, currentDealer: data.data }
    default:
      return state
  }
}

export default reducer