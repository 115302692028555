import { Input, Form, DatePicker, Select, Switch, Button, TimePicker, InputNumber, } from "antd"
import './FormItems.scss'

const FormItem = Form.Item
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker

export const FormInput = ({ name, required, type, label, placeholder, initialValue, styles, disabled, max, containerStyle, onChange }) => {
  return <FormItem
    initialValue={initialValue}
    label={label}
    name={name}
    style={containerStyle}
    rules={[
      {
        required,
        message: 'required',
      },

      {
        type: type === 'email' ? 'email' : null,
        message: 'The input is not valid E-mail!'
      },

    ]}
  >
    <Input placeholder={placeholder} className='form_item' style={styles} type={type === 'email' ? 'text' : type} disabled={disabled} maxLength={max} onChange={onChange && onChange} />
  </FormItem>
}
export const FormInputNumber = ({ name, required, type, label, placeholder, initialValue, styles, disabled, max, maxlength }) => {
  return <FormItem
    initialValue={initialValue}
    label={label}
    name={name}
    rules={[
      {
        required,
        message: 'required',
      },

    ]}
  >
    <InputNumber placeholder={placeholder} className='form_item' style={styles} disabled={disabled} max={max} maxLength={maxlength} />
  </FormItem>
}
export const FormDatePicker = ({ name, required, label, initialValue, styles }) => {
  return <FormItem
    initialValue={initialValue}
    label={label}
    name={name}
    rules={[
      {
        required,
        message: 'Required',
      },
    ]}
  >
    <DatePicker className='form_item' style={{ ...styles, marginRight: 12 }} />

  </FormItem>
}
export const FormDateRange = ({ name, required, label, initialValue, styles, containerStyle }) => {
  return <FormItem
    initialValue={initialValue}
    label={label}
    name={name}
    style={containerStyle}
    rules={[
      {
        required,
        message: 'Required',
      },
    ]}
  >
    <RangePicker className='form_item' style={{ ...styles, marginRight: 12 }} />

  </FormItem>
}
export const FormTimePicker = ({ name, required, label, initialValue, styles }) => {
  return <FormItem
    initialValue={initialValue}
    label={label}
    name={name}
    rules={[
      {
        required,
        message: 'Please input task',
      },
    ]}
  >
    <TimePicker className='form_item' format={'HH:mm'} style={styles} />

  </FormItem>
}

export const FormSelect = ({ name, required, label, placeholder, options, initialValue, styles, onChange, disabled, message, containerStyle, search }) => {
  return <FormItem

    label={label}
    initialValue={initialValue}
    name={name}
    style={containerStyle}
    rules={[
      {
        required,
        message: 'required',
      },
    ]}
  >
    <Select showSearch className='form_item' placeholder={placeholder} style={styles} onChange={onChange} disabled={disabled}>
      {options.length ? options.map(opt => <Option key={opt.value} value={opt.value}>{opt.label}</Option>) : null}
    </Select>

  </FormItem>
}

export const FormSwitch = ({ name, required, label, initialValue }) => {
  return <FormItem
    initialValue={initialValue}
    label={label}
    name={name}
    valuePropName="checked"
    rules={[
      {
        required,
        message: 'Please input task',
      },
    ]}
  >
    <Switch />

  </FormItem>
}
export const FormHiddenSubmitButton = ({ submitRef }) => {
  return <Form.Item
    style={{ display: 'none' }}
    wrapperCol={{
      offset: 8,
      span: 16,
    }}
  >
    <Button ref={submitRef} type="primary" htmlType="submit">
    </Button>
  </Form.Item>
}

export const FormInputArea = ({ name, required, label, placeholder, message, initialValue, styles }) => {
  return <FormItem
    initialValue={initialValue}
    label={label}
    name={name}
    rules={[
      {
        required,
        message: 'required'
      },
    ]}
  >
    <TextArea placeholder={placeholder} style={styles} />

  </FormItem>
}