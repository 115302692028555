
export const actions = {
  SET_ALL_DISTRIBUTOR_ACTIVITIES: "SET_ALL_DISTRIBUTOR_ACTIVITIES",
  SET_ALL_SUB_DISTRIBUTOR_ACTIVITIES: "SET_ALL_SUB_DISTRIBUTOR_ACTIVITIES",
  SET_ALL_DEALER_ACTIVITIES: "SET_ALL_DEALER_ACTIVITIES",
};

export const setAllDistributorActivities = data => ({
  type: actions.SET_ALL_DISTRIBUTOR_ACTIVITIES,
  data
})

export const setAllSubDistributorActivities = data => ({
  type: actions.SET_ALL_SUB_DISTRIBUTOR_ACTIVITIES,
  data
})

export const setAllDealerActivities = data => ({
  type: actions.SET_ALL_DEALER_ACTIVITIES,
  data
})
