import Dashboard from "../containers/Dashboard/Dashboard";
import PaymentActivities from "../containers/PaymentActicities/PaymentActivities";
import Payments from "../containers/Payments/Payments";
import Profile from "../containers/Profile/Profile";
import Users from "../containers/Users/Users";


// router configuration.
let routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    exact: true
  },
  {
    path: '/dashboard/users',
    name: 'users',
    component: Users,
    exact: true
  },
  {
    path: '/dashboard/payment-activities',
    name: 'activities',
    component: PaymentActivities,
    exact: true
  },
  {
    path: '/dashboard/payments',
    name: 'payments',
    component: Payments,
    exact: true
  },
  {
    path: '/dashboard/profile',
    name: 'profile',
    component: Profile,
  },
]

export default routes;