import { Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/actions/app.actions'
import { updateAppConfig } from '../../services/app.services'
import './Dashboard.scss'



const Dashboard = () => {
  const dispatch = useDispatch()

  const onSwitchChange = async (data) => {
    dispatch(setLoading(true))
    await dispatch(updateAppConfig(data))
    dispatch(setLoading(false))
  }

  const configs = useSelector(state => state.app.appConfigs)
  const user = useSelector(state => state.user.currentUser)

  return (
    <div>
      {(user?.role === 'admin' || user?.role === 'processor') ? <div>
        <div style={{ padding: 20, }}>
          <h2>Server Status</h2>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 320, height: 100, border: '1px solid #666', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20, margin: 20 }}>
            <h2>Simple Server </h2>
            <Switch checked={configs?.simpleStatus} onChange={(val) => onSwitchChange({ simpleStatus: val })} />
          </div>
          <div style={{ width: 320, height: 100, border: '1px solid #666', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20, margin: 20 }}>
            <h2>Boost Server </h2>
            <Switch checked={configs?.boostStatus} onChange={(val) => onSwitchChange({ boostStatus: val })} />
          </div>
          <div style={{ width: 320, height: 100, border: '1px solid #666', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20, margin: 20 }}>
            <h2>Processor Availablity </h2>
            <Switch checked={configs?.processorAvailablity} onChange={(val) => onSwitchChange({ processorAvailablity: val })} />
          </div>
        </div>
      </div>
        : <p>Dashboard</p>}
    </div>
  )
}

export default Dashboard