import { Form, Button } from "antd"
import Modal from "antd/lib/modal/Modal"
import { useRef } from "react";
import { FormHiddenSubmitButton, FormInput, FormInputArea, FormInputNumber, FormSelect } from "../../../components/FormItems/FormItems"
import './PaymentModal.scss'
import proptypes from 'prop-types'
import { useSelector } from "react-redux";




const simOptions = [
  { label: 'Simple', value: 'simple' },
  { label: 'Boost', value: 'boost' }
]

const addOnOptions = [
  { label: '$1', value: 1 },
  { label: '$2', value: 2 },
  { label: '$3', value: 3 },
  { label: '$4', value: 4 },
  { label: '$5', value: 5 },
  { label: '$6', value: 6 },
  { label: '$7', value: 7 },
  { label: '$8', value: 8 },
  { label: '$9', value: 9 },
  { label: '$10', value: 10 },
  { label: '$11', value: 11 },
  { label: '$12', value: 12 },
  { label: '$13', value: 13 },
  { label: '$14', value: 14 },
  { label: '$15', value: 15 },
]
const simplePlans = [
  { label: '$25', value: '$25', amount: 25, },
  { label: '$30', value: '$30', amount: 30, },
  { label: '$40', value: '$40', amount: 40, },
  { label: '$50', value: '$50', amount: 50, },
  { label: '$60', value: '$60', amount: 60, },
]
const boostPlans = [
  { label: '$35', value: '$35', amount: 35 },
  { label: '$50', value: '$50', amount: 50 },
  { label: '$60', value: '$60', amount: 60 },
]
const PaymentModal = ({ modalVisible, setModalVisible, currentPayment, onAdd, onUpdate, setCurrentPayment, pin }) => {


  const [form] = Form.useForm();
  const submitRef = useRef()



  const loggedUser = useSelector(state => state.user.currentUser)


  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onClose = () => {
    setModalVisible(false)
    form.resetFields()
    setCurrentPayment(null)
  }


  const onPlanChange = (value) => {
    let planOptions
    if (pin) {
      planOptions = boostPlans
    } else {
      planOptions = simplePlans
    }
    const plan = planOptions.find(p => p.value === value)
    form.setFieldsValue({ amount: plan.amount, profit: 0 })
  }



  return (
    <Modal
      className='todo_modal'
      style={{ borderRadius: 8 }}
      bodyStyle={{ borderRadius: 8 }}
      destroyOnClose
      title={currentPayment ? 'Update Payment' : 'Add Payment'}
      onOk={() => submitRef.current.click()}
      visible={modalVisible}
      onCancel={onClose}>
      <Form className='form' layout='vertical' form={form} preserve={false} onFinishFailed={onFinishFailed} onFinish={(values) => {
        if (currentPayment) {
          onUpdate(values)
          onClose()
        } else {
          onAdd(values)
          onClose()
        }
        setModalVisible(false)
      }}>
        {/* <FormInput label='Business' name='business' required type='text' placeholder='Enter Business' initialValue={currentPayment ? currentPayment.bsuiness : ''} styles={null} /> */}
        <FormSelect label='Carrier' name='sim' placeholder={'Select Sim'} options={simOptions} initialValue={currentPayment ? currentPayment.sim : pin ? 'boost' : 'simple'} disabled />
        <FormInputNumber maxlength={10} styles={{ width: '100%' }} label='Phone' name='phone' required type='text' placeholder='Enter Phone' initialValue={currentPayment ? currentPayment.phone : null} disabled={loggedUser?.role === 'processor'} />
        {pin ? <FormInput max={4} label='Pin' name='pin' required type='text' placeholder='Enter Pin' initialValue={currentPayment ? currentPayment.pin : ''} disabled={loggedUser?.role === 'processor'} /> : null}
        <FormSelect label='Plan' name='plan' onChange={(e) => onPlanChange(e)} placeholder={'Select Plan'} options={pin ? boostPlans : simplePlans} required initialValue={currentPayment ? currentPayment.plan : null} disabled={loggedUser?.role === 'processor'} />
        {pin ? <FormSelect label='Add on' name='addOn' placeholder={'Select Addon'} options={addOnOptions} initialValue={currentPayment ? currentPayment.addOn : 0} disabled={currentPayment} /> : null}
        <FormInputNumber name='amount' initialValue={currentPayment ? currentPayment.amount : 0} disabled styles={{ width: 0, height: 0, border: 'none' }} />

        {loggedUser?.role === 'dealer' ? <p>Available Amount:{loggedUser?.wallet?.amount}</p> : null}
        {loggedUser?.role === 'processor' ?
          <div>
            <FormInputArea label={'Comment'} name='comment' initialValue={currentPayment ? currentPayment.comment : ''} placeholder={'Comment'} />
            <div>
              <Button disabled={currentPayment?.status === 'completed'} type="primary" style={{ marginRight: 12 }} onClick={() => {
                form.validateFields().then(async (values) => {
                  onUpdate({ ...values, status: 'completed' })
                  onClose()
                }).catch(err => {
                  form.scrollToField(err?.errorFields[0])
                })
              }}>
                Completed
              </Button>
              <Button disabled={!(currentPayment?.status === 'pending' || currentPayment?.status === 'in-progress')} type="danger" style={{ marginRight: 12 }} onClick={() => {
                form.validateFields().then(async (values) => {
                  onUpdate({ ...values, status: 'rejected' })
                  onClose()
                }).catch(err => {
                  form.scrollToField(err?.errorFields[0])
                })
              }}>
                Reject
              </Button>
              <Button disabled={currentPayment?.status !== 'completed'} style={{ marginRight: 12 }} type="primary" onClick={() => {
                form.validateFields().then(async (values) => {
                  onUpdate({ ...values, status: 'refunded' })
                  onClose()
                }).catch(err => {
                  form.scrollToField(err?.errorFields[0])
                })
              }}>
                Refund
              </Button>

              <Button disabled={currentPayment?.status !== 'pending'} type="dashed" style={{ marginRight: 12 }} onClick={() => {
                form.validateFields().then(async (values) => {
                  onUpdate({ ...values, status: 'in-progress' })
                  onClose()
                }).catch(err => {
                  form.scrollToField(err?.errorFields[0])
                })
              }}>
                In Progress
              </Button>
            </div>

          </div>
          : null}
        <FormHiddenSubmitButton submitRef={submitRef} />
      </Form>
    </Modal >
  )
}

PaymentModal.propTypes = {
  modalVisible: proptypes.bool.isRequired,
  setModalVisible: proptypes.func.isRequired,
  onAdd: proptypes.func.isRequired,
  currentPayment: proptypes.object,
  onUpdate: proptypes.func.isRequired,
  onDelete: proptypes.func.isRequired
};

export default PaymentModal
