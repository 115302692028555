import { actions } from '../actions/payments.actions';

const INITIAL_STATE = {
  allSimpleActivities: [],
  allBoostActivities: [],
  currentPayment: null,
  boostSearch: {
    skip: 0,
    limit: 25,
    type: '',
    page: 1,
    search: null,
  },
  simpleSearch: {
    skip: 0,
    limit: 25,
    search: null,
    type: '',
    page: 1,

  },
  simpleCount: 0,
  boostCount: 0
}

const reducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case actions.SET_ALL_SIMPLE_ACTIVITIES:
      return { ...state, allSimpleActivities: data }
    case actions.SET_ALL_BOOST_ACTIVITIES:
      return { ...state, allBoostActivities: data }
    case actions.SET_CURRENT_PAYMENT:
      return { ...state, currentPayment: data }
    case actions.SET_BOOST_SEARCH:
      return { ...state, boostSearch: data }
    case actions.SET_SIMPLE_SEARCH:
      return { ...state, simpleSearch: data }
    case actions.SET_BOOST_COUNT:
      return { ...state, boostCount: data }
    case actions.SET_SIMPLE_COUNT:
      return { ...state, simpleCount: data }
    default:
      return state
  }
}

export default reducer