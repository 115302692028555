import { DeleteOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Select, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SubDistributorModal from './SubDistributorModal/SubDistributorModal';
import { setAllSubDistributors, setCurrentSubDistributor } from '../../../redux/actions/user.actions';
import { addNewSubDistributor, deleteSubDistributor, getAllSubDistributor, updateSubDistributor, updateSubDistributorAmount } from '../../../services/subDistributor.services';
import { getAllDistributors } from '../../../services/distributor.services';
import { setLoading } from '../../../redux/actions/app.actions';
import { searchUser } from '../../../services/user.services';

const Option = Select.Option
let timeOut = null
export default function SubSubDistributors() {

  const dispatch = useDispatch();



  const [modalVisible, setModalVisible] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [user, setUser] = useState(null)


  const allSubDistributors = useSelector(state => state.user.allSubDistributors)
  const currentSubDistributor = useSelector(state => state.user.currentSubDistributor)
  const loggedUser = useSelector(state => state.user.currentUser)

  const getData = async () => {
    dispatch(setLoading(true))
    await dispatch(getAllSubDistributor())
    if (loggedUser?.role === 'admin') {
      await dispatch(getAllDistributors())
    }
    dispatch(setLoading(false))
  }

  useEffect(() => {
    getData()
  }, []); //eslint-disable-line

  const onAddHandler = async (values) => {
    dispatch(setLoading(true))
    let data = { ...values }
    if (!values.distributor) {
      data.distributor = loggedUser._id
    }
    await dispatch(addNewSubDistributor({ ...data, role: 'subDistributor' }, allSubDistributors))
    dispatch(setLoading(false))
    dispatch(setCurrentSubDistributor(null))
  }

  const onUpdateHandler = async (values) => {
    dispatch(setLoading(true))
    delete values.amount
    await dispatch(updateSubDistributor(currentSubDistributor?._id, { ...values, }, allSubDistributors))
    setUser(null)
    setOptions([])
    getData()
    dispatch(setLoading(false))
    dispatch(setCurrentSubDistributor(null))
  }


  const onDeleteHandler = async (id) => {
    dispatch(setLoading(true))
    await dispatch(deleteSubDistributor(id, allSubDistributors))
    dispatch(setLoading(false))
    setUser(null)
    setOptions([])
    getData()
    dispatch(setCurrentSubDistributor(null))
  }
  const onAmountUpdate = async (id, data) => {
    await dispatch(updateSubDistributorAmount(id, data, allSubDistributors))
    setUser(null)
    setOptions([])
    getData()
    dispatch(setLoading(false))
    dispatch(setCurrentSubDistributor(null))
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Distributor',
      dataIndex: 'distributor',
      key: 'distributer',
      render: (col) => col?.name
    },
    {
      title: 'Amount',
      dataIndex: 'wallet',
      key: 'amount',
      render: txt => txt ? `$${txt?.amount}` : '$0'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      width: 180,
      render: (text, record) => (
        <span>
          <Button type='primary' icon={<EditOutlined />} onClick={() => {
            dispatch(setCurrentSubDistributor(record))
            setModalVisible(true)
          }} />
          <Divider type="vertical" />

          <Popconfirm disabled={false} placement='topRight' title="Are you sure to delete this sub-distributor?" onConfirm={() => {
            onDeleteHandler(record._id)
          }}
          >
            <Button disabled={false} type='danger' icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    }
  ];
  const onSearchUser = (value) => {
    setSearchText(value)
    clearTimeout(timeOut)
    if (value) {
      timeOut = setTimeout(async () => {
        setFetching(true)
        const options = await searchUser(value, 'subDistributor')
        setOptions(options)
        setFetching(false)
      }, 1000)
    }
  }
  return (
    <div>

      <SubDistributorModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        currentSubDistributor={currentSubDistributor}
        setCurrentSubDistributor={(value) => dispatch(setCurrentSubDistributor(value))}
        onAdd={onAddHandler}
        onDelete={onDeleteHandler}
        onUpdate={onUpdateHandler}
        onAmountUpdate={onAmountUpdate}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
        {loggedUser?.role !== 'dealer' &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginRight: 20 }}>User: </p>
            <Select
              className='form_item'
              autoClearSearchValue={false}
              allowClear
              showSearch
              value={user}
              style={{ width: 400, marginRight: 20 }}
              filterOption={false}
              searchValue={searchText}
              onSearch={onSearchUser}
              onChange={(val) => {
                setUser(val)
                dispatch(setAllSubDistributors(options.filter(d => d._id === val)))
              }}
              notFoundContent={fetching ? <Spin size="small" /> : null}
            >
              {options.map(val => <Option key={val._id} label={val.name} value={val._id}>{val.name} ({val.role})</Option>)}
            </Select>
            <Button icon={<UndoOutlined />} onClick={() => {
              setOptions([])
              getData()
              setUser(null)

            }} />
          </div>}
        <Button type='primary' onClick={() => setModalVisible(true)}>
          Add Sub Distributor
        </Button>
      </div>

      <div style={{ overflow: 'auto', height: '70vh' }}>
        <Table dataSource={allSubDistributors} columns={columns} />
      </div>
    </div>
  )
}
