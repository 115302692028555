import { Select, Tabs } from 'antd';
import BoostPayment from './BoostPayment';
import BoostLogo from '../../assets/images/boost_logo.png';
import SimplePayment from './SimplePayment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPayments } from '../../services/payments.services';
import { useEffect, useState } from 'react';
import bell from '../../assets/images/bell.wav'
import { getAppConfigs } from '../../services/app.services';
const TabPane = Tabs.TabPane

let interval = null;

export default function Payments() {
  const [pending, setPending] = useState({
    pendingBoost: null,
    pendingSimple: null
  })
  // const boostPayments = useSelector(state => state.payments.allBoostActivities)
  // const simplePayments = useSelector(state => state.payments.allSimpleActivities)
  const user = useSelector(state => state.user.currentUser)
  const simpleCount = useSelector(state => state.payments.simpleCount)
  const boostCount = useSelector(state => state.payments.boostCount)
  const dispatch = useDispatch()



  if (user?.role === 'processor') {

    clearInterval(interval)
    interval = setInterval(async () => {
      await dispatch(getAppConfigs())
      const boostPayments = await dispatch(getAllPayments('boost', 0, false))
      const simplePayments = await dispatch(getAllPayments('simple', 0, false))
      const pendingBoost = boostPayments.payments.find(o => o.status === 'pending')
      setPending((prev) => ({ ...prev, pendingBoost: pendingBoost }))
      const pendingSimple = simplePayments.payments.find(o => o.status === 'pending')
      setPending((prev) => ({ ...prev, pendingSimple: pendingSimple }))
      if (pendingBoost || pendingSimple) {
        var audio = new Audio(bell);
        audio.play();
      }
    }, 10000)
  }

  if (user?.role === 'dealer') {

    clearInterval(interval)
    interval = setInterval(async () => {
      await dispatch(getAppConfigs())
    }, 10000)
  }
  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])



  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Payments</h2>

      </div>

      <Tabs defaultActiveKey='0'>
        <TabPane tab={
          <span>
            <img src={BoostLogo} width={16} style={{ marginRight: 8 }} />
            {`Boost (${boostCount})`}
            <span style={{ color: 'red' }}>{pending.pendingBoost ? ' * ' : null}</span>
          </span>
        } key='0'>
          <BoostPayment />
        </TabPane>
        <TabPane tab={<span>

          {`Simple (${simpleCount})`}
          <span style={{ color: 'red' }}>{pending.pendingSimple ? ' * ' : null}</span>
        </span>} key='1'>
          <SimplePayment />
        </TabPane>
      </Tabs>
    </div>
  )
}
