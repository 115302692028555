
import { Button, Form } from 'antd';
import { replace } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput } from '../../components/FormItems/FormItems';
import { setLoading } from '../../redux/actions/app.actions';
import { changePassword } from '../../services/auth.services';
import { clearStorage } from '../../utils/storage';

export default function Profile() {
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const loggedUser = useSelector(state => state.user.currentUser)
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }
  return (
    <div>
      <div style={{ padding: 24, }}>
        <h2>Personal Information</h2>

        <div style={{ width: 400, marginTop: 32 }}>
          <h2 style={{ marginBottom: 24 }}>Change Password</h2>
          <Form layout='vertical' form={form} preserve={false} onFinishFailed={onFinishFailed} onFinish={(values) => {
            form.validateFields().then(async (values) => {
              dispatch(setLoading(true))
              await changePassword({ ...values })
              dispatch(setLoading(false))
            }).catch(err => {
              form.scrollToField(err?.errorFields[0])
            })
          }}>
            <FormInput label='Current Password' name='oldPassword' required placeholder='Current Password' initialValue={''} styles={null} type={'password'} message={'Enter current password'} />
            <FormInput label='New Password' name='newPassword' required placeholder='New Password' initialValue={''} styles={null} type={'password'} message={'Enter new password'} />
            <Button type='primary' htmlType="submit">{'Save'}</Button>
          </Form>
        </div>
        <Button style={{ marginTop: 24 }} onClick={() => {
          clearStorage()
          dispatch(replace('/'))
        }}>Logout</Button>
      </div>
    </div>
  )
}
