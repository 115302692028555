import { openNotificationWithIcon } from "../utils/Notification"
import { request } from "./verb.services"

export const searchUser = (search, role) => {
  return request('users/search', 'get', null, true, { search, role })
    .then(({ data }) => {
      return data.data
    })
    .catch((e) => {
      console.log(e)
      openNotificationWithIcon('error', 'Error!', e.response.data.message);
    })

}