import { setAllDealerActivities } from "../redux/actions/activity.actions"
import { setAllDealers } from "../redux/actions/user.actions"
import { openNotificationWithIcon } from "../utils/Notification"
import { request } from "./verb.services"

export const getAllDealer = () => {
  return dispatch => {
    return request('users', 'get', null, true, { role: 'dealer' })
      .then(({ data }) => {
        dispatch(setAllDealers(data.data))
        return data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const addNewDealer = (data, prevSource) => {
  return dispatch => {
    // dispatch(setAllDealer([...prevSource, { ...data }]))
    return request('users/register', 'post', data, true)
      .then(({ data }) => {
        dispatch(setAllDealers([{ ...data.data }, ...prevSource]))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const updateDealer = (id, data, prevSource) => {
  return dispatch => {
    return request(`users/${id}`, 'put', data, true)
      .then(({ data }) => {
        let newSource = [...prevSource]
        newSource = newSource.map(o => {
          if (o._id === id) {
            return { ...data.data }
          }
          return o;
        })
        dispatch(setAllDealers(newSource))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const deleteDealer = (id, prevSource) => {
  return dispatch => {
    return request(`users/${id}`, 'delete', null, true)
      .then(() => {
        let newSource = [...prevSource]
        newSource = newSource.filter(o => o._id !== id)
        dispatch(setAllDealers(newSource))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const updateDealerAmount = (id, data, prevSource) => {
  return dispatch => {
    return request(`wallets/${id}`, 'put', data, true)
      .then(({ data }) => {
        let newSource = [...prevSource]
        newSource = newSource.map(o => {
          if (o._id === id) {
            return { ...data.data }
          }
          return o;
        })
        dispatch(setAllDealers(newSource))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const getDealerPaymentActivities = () => {
  return dispatch => {
    return request('activities', 'get', null, true, { role: 'dealer' })
      .then(({ data }) => {
        dispatch(setAllDealerActivities(data.data))
        return data.data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}