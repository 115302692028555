import { setConfigs } from "../redux/actions/app.actions"
import { openNotificationWithIcon } from "../utils/Notification"
import { request } from "./verb.services"

export const getAppConfigs = () => {
  return dispatch => {
    return request('configs', 'get', null, true, null)
      .then(({ data }) => {
        dispatch(setConfigs(data.data))
        return data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const updateAppConfig = (data) => {
  return dispatch => {
    return request('configs', 'put', data, true, null)
      .then(({ data }) => {
        dispatch(setConfigs(data.data))
        return data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}