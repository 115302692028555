// const api = {
//   url: 'http://localhost:4000/api/',
//   // url: 'https://api-test.bpays101.com/api/'
// };

// export default api



const getEnv = () => {
  const appEnv = window.location.host.split('.')[0];
  switch (appEnv) {
    case 'bpay101':
      return 'prod';
    case 'testing':
      return 'test';
    default:
      return 'local';
  }
}

const apiUrl = () => {
  switch (getEnv()) {
    case 'test':
      return 'https://api-test.bpay101.com/api/';
    case 'prod':
      return 'https://api.bpay101.com/api/';
    default:
      return 'http://localhost:4000/api/';
      // return 'http://localhost:4000/api/';
  }
}


const api = {
  url: apiUrl(),
};

export default api