import { actions } from '../actions/app.actions';

const INITIAL_STATE = {
  loading: false,
  message: '',
  appConfigs: {
    simpleStatus: true,
    boostStatus: true
  }
}
const reducer = (state = INITIAL_STATE, { type, data, message }) => {
  switch (type) {
    case actions.SET_LOADING:
      return { ...state, loading: data, message: message ? message : '' }
    case actions.SET_CONFIGS:
      return { ...state, appConfigs: data }
    default:
      return state
  }
}
export default reducer