import { setBoostCount, setBoostPayments, setSimpleCount, setSimplePayments } from "../redux/actions/payments.actions"
import { openNotificationWithIcon } from "../utils/Notification"
import { verifyUser } from "./auth.services"
import { request } from "./verb.services"

export const getAllPayments = (sim, skip = 0, disp = true, limit=25) => {
  return dispatch => {
    return request('payments', 'get', null, true, { sim, skip, limit })
      .then(({ data }) => {
        if (sim === 'simple') {
          if (disp) {
            dispatch(setSimplePayments(data.data.payments))
            dispatch(setSimpleCount(data.data.count))
          }
        } else {
          if (disp) {
            dispatch(setBoostPayments(data.data.payments))
            dispatch(setBoostCount(data.data.count))
          }
        }
        return data.data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const getPaymentCsv = (sim, query) => {
  return request('payments', 'get', null, true, { sim, scope: 'full', query })
    .then(({ data }) => {
      return data.data.payments
    })
    .catch((e) => {
      console.log(e)
      openNotificationWithIcon('error', 'Error!', e.response.data.message);
    })
}

export const createPayment = (data, prevSource) => {
  return dispatch => {
    return request('payments', 'post', data, true, null)
      .then(({ data }) => {
        if (data.data.sim === 'simple') {
          dispatch(setSimplePayments([{ ...data.data }, ...prevSource]))
        } else {
          dispatch(setBoostPayments([{ ...data.data }, ...prevSource]))
        }
        dispatch(verifyUser())
        return data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const updatePayment = (id, data, prevSource) => {
  return dispatch => {
    return request(`payments/${id}`, 'put', data, true)
      .then(({ data }) => {
        let newSource = [...prevSource]
        newSource = newSource.map(o => {
          if (o._id === id) {
            return { ...data.data }
          }
          return o;
        })
        if (data.data.sim === 'simple') {
          dispatch(setSimplePayments(newSource))
        } else {
          dispatch(setBoostPayments(newSource))
        }
        dispatch(verifyUser())
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const searchPayments = (sim, query, skip = 0, limit) => {
  return dispatch => {
    return request('payments', 'get', null, true, { sim, query, skip, limit })
      .then(({ data }) => {
        if (sim === 'simple') {
          dispatch(setSimplePayments(data.data.payments))
          dispatch(setSimpleCount(data.data.count))
        } else {
          dispatch(setBoostPayments(data.data.payments))
          dispatch(setBoostCount(data.data.count))
        }
        return data.data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}