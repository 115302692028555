import { Button, Table } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/actions/app.actions';
import { getDealerPaymentActivities } from '../../services/dealer.services';
import convertToCsv from '../../utils/csvExport';

export default function DealerActivities() {
  const dispatch = useDispatch()

  const getData = async () => {
    dispatch(setLoading(true))
    await dispatch(getDealerPaymentActivities())
    dispatch(setLoading(false))
  }

  useEffect(() => {
    getData()
  }, []); //eslint-disable-line

  const currentUser = useSelector(state => state.user.currentUser)
  const activities = useSelector(state => state.activities.allDealerActivities)
  const columns = [

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: txt => txt ? `$${txt}` : '$0'
    },
    {
      title: 'Status',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'By',
      dataIndex: 'creator',
      key: 'creator',
      render: col => col?.name
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: col => `${moment(col).tz('america/chicago').format('DD-MM-YYYY hh:m a')} (CT)`
    },
  ];
  if (currentUser?.role !== 'dealer') {
    columns.splice(0, 0, {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: col => col?.name
    })
  }
  return <div style={{ overflow: 'scroll', height: '66vh' }}>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
      <Button type='primary' onClick={async () => {
        let data = await dispatch(getDealerPaymentActivities())
        data = data.map(payment => {
          const newPayment = {
            _id: payment._id,
            user: payment.user?.name,
            amount: payment.amount,
            status: payment.type,
            by: payment.creator?.name,
            date: `${moment(payment.createdAt).tz('america/chicago').format('MM/DD/YYYY h:mm a')} (CT)`,
            lastUpdated: `${moment(payment.updatedAt).tz('america/chicago').format('MM/DD/YYYY h:mm a')} (CT)`,
          }
          return newPayment
        })
        convertToCsv(data, 'dealerActivity.csv')
      }}>
        Export CSV
      </Button>
    </div>
    <Table dataSource={activities} columns={columns} />
  </div>;
}
