
export const actions = {
  SET_ALL_SIMPLE_ACTIVITIES: "SET_ALL_SIMPLE_ACTIVITIES",
  SET_ALL_BOOST_ACTIVITIES: "SET_ALL_BOOST_ACTIVITIES",
  SET_CURRENT_PAYMENT: "SET_CURRENT_PAYMENT",
  SET_SIMPLE_COUNT: "SET_SIMPLE_COUNT",
  SET_BOOST_SEARCH: "SET_BOOST_SEARCH",
  SET_SIMPLE_SEARCH: "SET_SIMPLE_SEARCH",
  SET_BOOST_COUNT: "SET_BOOST_COUNT"
};

export const setSimplePayments = data => ({
  type: actions.SET_ALL_SIMPLE_ACTIVITIES,
  data
})
export const setBoostPayments = data => ({
  type: actions.SET_ALL_BOOST_ACTIVITIES,
  data
})
export const setCurrentPayment = data => ({
  type: actions.SET_CURRENT_PAYMENT,
  data
})
export const setSimpleCount = data => ({
  type: actions.SET_SIMPLE_COUNT,
  data
})
export const setBoostCount = data => ({
  type: actions.SET_BOOST_COUNT,
  data
})
export const setBoostSearch = data => ({
  type: actions.SET_BOOST_SEARCH,
  data
})
export const setSimpleSearch = data => ({
  type: actions.SET_SIMPLE_SEARCH,
  data
})