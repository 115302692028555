import { setAllDistributorActivities } from "../redux/actions/activity.actions"
import { setAllDistributors } from "../redux/actions/user.actions"
import { openNotificationWithIcon } from "../utils/Notification"
import { request } from "./verb.services"

export const getAllDistributors = () => {
  return dispatch => {
    return request('users', 'get', null, true, { role: 'distributor' })
      .then(({ data }) => {
        dispatch(setAllDistributors(data.data))
        return data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const addNewDistributor = (data, prevSource) => {
  return dispatch => {
    // dispatch(setAllDistributors([...prevSource, { ...data }]))
    return request('users/register', 'post', data, true)
      .then(({ data }) => {
        dispatch(setAllDistributors([{ ...data.data }, ...prevSource]))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const updateDistributor = (id, data, prevSource) => {
  return dispatch => {
    return request(`users/${id}`, 'put', data, true)
      .then(({ data }) => {
        let newSource = [...prevSource]
        newSource = newSource.map(o => {
          if (o._id === id) {
            return { ...data.data }
          }
          return o;
        })
        dispatch(setAllDistributors(newSource))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const deleteDistributor = (id, prevSource) => {
  return dispatch => {
    return request(`users/${id}`, 'delete', null, true)
      .then(() => {
        let newSource = [...prevSource]
        newSource = newSource.filter(o => o._id !== id)
        dispatch(setAllDistributors(newSource))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}


export const getDistributorPaymentActivities = () => {
  return dispatch => {
    return request('activities', 'get', null, true, { role: 'distributor' })
      .then(({ data }) => {
        dispatch(setAllDistributorActivities(data.data))
        return data.data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}