
export const actions = {
  SET_LOADING: 'SET_LOADING',
  SET_CONFIGS: 'SET_CONFIGS'
};

export const setLoading = (data, message) => ({
  type: actions.SET_LOADING,
  data,
  message
})

export const setConfigs = (data) => ({
  type: actions.SET_CONFIGS,
  data
})
