/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

import axios from 'axios';
import moment from 'moment';
import apiObj from '../utils/api';
import { clearStorage, getItem } from '../utils/storage';

const api = apiObj.url;


export const fetchToken = () => {

  const tokenContainer = getItem('tokenContainer')
  return `${tokenContainer.token}`
};

let lastApiTime = moment()


let timeOut = null

const updateLastActive = () => {
  if (timeOut) {
    clearTimeout(timeOut)
    timeOut = setTimeout(() => {
      console.log('register')
      if (moment().diff(lastApiTime, 'minutes') >= 5) {
        clearStorage()
      }
    }, 300000);
  } else {
    timeOut = setTimeout(() => {
      if (moment().diff(lastApiTime, 'minutes') >= 5) {
        clearStorage()
      }
    }, 300000);
  }
}


export const request = (url, type, data, headers, params, extraOptions = null) => new Promise((resolve, reject) => {

  lastApiTime = moment()
  updateLastActive()

  const request = {
    'method': type,
    'url': api + url,
    ...extraOptions,
  };
  if (headers) {
    request.headers = {
      'x-access-token': fetchToken(),
    }
    if (headers.contentType) {
      request.headers['content-type'] = headers.contentType;
    }
  }
  type !== 'get' && (request.data = data);
  params && (request.params = params);
  if (!request.url.includes('refresh')) {
    axios(request).then(resolve, reject);
  }
});