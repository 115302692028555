import { setAllSubDistributorActivities } from "../redux/actions/activity.actions"
import { setAllSubDistributors } from "../redux/actions/user.actions"
import { openNotificationWithIcon } from "../utils/Notification"
import { verifyUser } from "./auth.services"
import { request } from "./verb.services"

export const getAllSubDistributor = () => {
  return dispatch => {
    return request('users', 'get', null, true, { role: 'subDistributor' })
      .then(({ data }) => {
        dispatch(setAllSubDistributors(data.data))
        return data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const addNewSubDistributor = (data, prevSource) => {
  return dispatch => {
    // dispatch(setAllSubDistributor([...prevSource, { ...data }]))
    return request('users/register', 'post', data, true)
      .then(({ data }) => {
        dispatch(setAllSubDistributors([{ ...data.data }, ...prevSource]))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const updateSubDistributor = (id, data, prevSource) => {
  return dispatch => {
    return request(`users/${id}`, 'put', data, true)
      .then(({ data }) => {
        let newSource = [...prevSource]
        newSource = newSource.map(o => {
          if (o._id === id) {
            return { ...data.data }
          }
          return o;
        })
        dispatch(setAllSubDistributors(newSource))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const deleteSubDistributor = (id, prevSource) => {
  return dispatch => {
    return request(`users/${id}`, 'delete', null, true)
      .then(() => {
        let newSource = [...prevSource]
        newSource = newSource.filter(o => o._id !== id)
        dispatch(setAllSubDistributors(newSource))
        dispatch(verifyUser())
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const updateSubDistributorAmount = (id, data, prevSource) => {
  return dispatch => {
    return request(`wallets/${id}`, 'put', data, true)
      .then(({ data }) => {
        let newSource = [...prevSource]
        newSource = newSource.map(o => {
          if (o._id === id) {
            return { ...data.data }
          }
          return o;
        })
        dispatch(setAllSubDistributors(newSource))
        dispatch(verifyUser())
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const getSubDistributorPaymentActivities = () => {
  return dispatch => {
    return request('activities', 'get', null, true, { role: 'subDistributor' })
      .then(({ data }) => {
        dispatch(setAllSubDistributorActivities(data.data))
        return data.data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}