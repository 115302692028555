import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import activities from './activity.reducer';
import user from './user.reducer';
import payments from './payments.reducer';
import app from './app.reducer';


// Root Reducer.
const reducer = history => combineReducers({
  router: connectRouter(history),
  activities,
  user,
  payments,
  app
});

export default reducer