import { RedoOutlined } from '@ant-design/icons'
import { Button, Form, Select, Spin } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getPaymentCsv } from '../../services/payments.services'
import { searchUser } from '../../services/user.services'
import convertToCsv from '../../utils/csvExport'
import { FormDateRange, FormSelect } from '../FormItems/FormItems'
const Option = Select.Option
let timeOut = null

export default function Search({ onSearch, onClear, sim, }) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [user, setUser] = useState(null)
  const [form] = Form.useForm()
  const onSubmit = () => {
    form.validateFields().then(async (values) => {

      onSearch({ ...values, user })
    }).catch(err => {
      form.scrollToField(err?.errorFields[0])
    })
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };
  const loggedUser = useSelector(state => state.user.currentUser)

  const onSearchUser = (value) => {
    setSearchText(value)
    clearTimeout(timeOut)
    if (value) {
      timeOut = setTimeout(async () => {
        setFetching(true)
        const options = await searchUser(value)
        setOptions(options)
        setFetching(false)
      }, 1000)
    }
  }
  const simplePlans = [
    { label: '$25', value: '$25', amount: 25, },
    { label: '$30', value: '$30', amount: 30, },
    { label: '$40', value: '$40', amount: 40, },
    { label: '$50', value: '$50', amount: 50, },
    { label: '$60', value: '$60', amount: 60, },
  ]
  const boostPlans = [
    { label: '$35', value: '$35', amount: 35 },
    { label: '$50', value: '$50', amount: 50 },
    { label: '$60', value: '$60', amount: 60 },
  ]

  return (
    <div style={{ margin: 12 }}>
      <Form style={{ display: 'flex', }} form={form} onFinishFailed={onFinishFailed} onFinish={onSubmit} preserve={false}>
        {loggedUser?.role !== 'dealer' &&
          <div style={{ display: 'flex', }}>
            <p style={{ marginRight: 20 }}>User: </p>
            <Select
              className='form_item'
              autoClearSearchValue={false}
              value={user}
              allowClear
              showSearch
              style={{ width: 400, marginRight: 20 }}
              filterOption={false}
              searchValue={searchText}
              onSearch={onSearchUser}
              onChange={(val) => setUser(val)}
              notFoundContent={fetching ? <Spin size="small" /> : null}
            >
              {options.map(val => <Option key={val._id} label={val.name} value={JSON.stringify({ user: val._id, role: val.role })}>{val.name} ({val.role})</Option>)}
            </Select>
          </div>}
        <FormSelect
          label={'Plan'}
          name='plan'
          options={sim === 'simple' ? simplePlans : boostPlans}
          containerStyle={{ flex: 1, marginRight: 12 }}
        />
        <FormSelect name={'status'} label={'Status'} options={[{ label: 'Pending', value: 'pending' }, { label: 'In Progress', value: 'in-progress' }, { label: 'Completed', value: 'completed' }, { label: 'Rejected', value: 'rejected' }, { label: 'Cancelled', value: 'cancelled' }, { label: 'Refunded', value: 'refunded' }]} containerStyle={{ flex: 1, marginRight: 12 }} />
        <FormDateRange name={'date'} label={'Time Range'} containerStyle={{ flex: 1 }} />
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={onSubmit}>Search</Button>
        <Button style={{ marginLeft: 12 }} type='primary' onClick={async () => {
          form.validateFields().then(async (values) => {
            let newData = {}
            for (const key in values) {
              if (values[key]) {
                if (key === 'date') {
                  values[key] = [moment(values[key][0]).tz('america/chicago').startOf('day'), moment(values[key][1]).tz('america/chicago').endOf('day')]
                }
                newData = { ...newData, [key]: values[key] }
              }
            }

            let data = await getPaymentCsv(sim, { ...newData, user })
            data = data.map(payment => {
              if (sim === 'simple') {
                const newPayment = {
                  _id: payment._id,
                  distributor: payment.distributor?.name,
                  subDistributor: payment.subDistributor?.name,
                  dealer: payment.dealer?.name,
                  dateSubmitted: `${moment(payment.createdAt).tz('america/chicago').format('MM/DD/YYYY h:mm a')} (CT)`,
                  phone: payment.phone,
                  carrier: payment.sim,
                  plan: payment.plan,
                  status: payment.status,
                  dateCompleted: `${moment(payment.updatedAt).tz('america/chicago').format('MM/DD/YYYY h:mm a')}(CT)`,
                  comment: payment.comment,
                }
                if (loggedUser?.role !== 'dealer') {
                  switch (payment.plan) {
                    case '$25':
                      newPayment.cost = '$19.22'
                      break;
                    case '$30':
                      newPayment.cost = '$24.15'
                      break;
                    case '$40':
                      newPayment.cost = '$25.30'
                      break;
                    case '$50':
                      newPayment.cost = '$26.45'
                      break;
                    default:
                      newPayment.cost = '$26.45'
                  }
                  switch (payment.plan) {
                    case '$25':
                      newPayment.profit = '$2.03'
                      break;
                    case '$30':
                      newPayment.profit = '$1.35'
                      break;
                    case '$40':
                      newPayment.profit = '$8.70'
                      break;
                    case '$50':
                      newPayment.profit = '$16.05'
                      break;
                    default:
                      newPayment.profit = '$24.55'
                  }
                }
                return newPayment
              } else {
                const newPayment = {
                  _id: payment._id,
                  distributor: payment.distributor?.name,
                  subDistributor: payment.subDistributor?.name,
                  dealer: payment.dealer?.name,
                  dateSubmitted: `${moment(payment.createdAt).tz('america/chicago').format('MM/DD/YYYY h:mm a')} (CT)`,
                  carrier: payment.sim,
                  phone: payment.phone,
                  pin: payment.pin,
                  plan: payment.plan,
                  addOn: payment.addOn,
                  status: payment.status,
                  dateCompleted: `${moment(payment.updatedAt).tz('america/chicago').format('MM/DD/YYYY h:mm a')} (CT)`,
                  comment: payment.comment
                }
                return newPayment
              }
            })
            convertToCsv(data, 'payments.csv')
          }).catch(err => {
            form.scrollToField(err?.errorFields[0])
          })

        }}>
          Export to CSV
        </Button>
        <Button

          icon={<RedoOutlined />}
          style={{ marginLeft: 12 }}
          type='default'
          onClick={() => {
            form.resetFields()
            setSearchText('')
            setUser(null)
            onClear()
          }} />
      </div>

    </div >
  )
}
