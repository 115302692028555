import { actions } from '../actions/activity.actions';

const INITIAL_STATE = {
  allDistributorActivities: [],
  allSubDistributorActivities: [],
  allDealerActivities: [],
}

const reducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case actions.SET_ALL_DISTRIBUTOR_ACTIVITIES:
      return { ...state, allDistributorActivities: data }
    case actions.SET_ALL_SUB_DISTRIBUTOR_ACTIVITIES:
      return { ...state, allSubDistributorActivities: data }
    case actions.SET_ALL_DEALER_ACTIVITIES:
      return { ...state, allDealerActivities: data }
    default:
      return state
  }
}

export default reducer