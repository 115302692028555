import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DistributorModal from './DistributorModal/DistributorModal';
import { setCurrentDistributor } from '../../../redux/actions/user.actions';
import { addNewDistributor, deleteDistributor, getAllDistributors, updateDistributor } from '../../../services/distributor.services';
import { setLoading } from '../../../redux/actions/app.actions';

export default function Distributors() {

  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false)

  const allDistributors = useSelector(state => state.user.allDistributors)
  const currentDistributor = useSelector(state => state.user.currentDistributor)

  const getData = async () => {
    dispatch(setLoading(true))
    await dispatch(getAllDistributors())
    dispatch(setLoading(false))

  }

  useEffect(() => {
    getData()
  }, []); //eslint-disable-line



  const onAddHandler = async (values) => {
    dispatch(setLoading(true))
    await dispatch(addNewDistributor({ ...values, role: 'distributor' }, allDistributors))
    dispatch(setLoading(false))
    dispatch(setCurrentDistributor(null))

  }

  const onUpdateHandler = async (values) => {
    dispatch(setLoading(true))
    await dispatch(updateDistributor(currentDistributor?._id, { ...values, }, allDistributors))
    dispatch(setLoading(false))
    dispatch(setCurrentDistributor(null))

  }




  const onDeleteHandler = async (id) => {
    dispatch(setLoading(true))
    await dispatch(deleteDistributor(id, allDistributors))
    dispatch(setLoading(false))
    dispatch(setCurrentDistributor(null))
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Amount',
      dataIndex: 'wallet',
      key: 'credists',
      render: txt => txt ? `$${txt?.amount}` : '$0'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      width: 180,
      render: (text, record) => (
        <span>
          <Button type='primary' icon={<EditOutlined />} onClick={() => {
            dispatch(setCurrentDistributor(record))
            setModalVisible(true)
          }} />
          <Divider type="vertical" />

          <Popconfirm disabled={false} placement='topRight' title="Are you sure to delete this distributor?" onConfirm={() => {
            onDeleteHandler(record._id)

          }}
          >
            <Button disabled={false} type='danger' icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    }
  ];

  return (
    <div style={{ overflow: 'auto' }}>
      <DistributorModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        currentDistributor={currentDistributor}
        setCurrentDistributor={(value) => dispatch(setCurrentDistributor(value))}
        onAdd={onAddHandler}
        onDelete={onDeleteHandler}
        onUpdate={onUpdateHandler}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
        <Button type='primary' onClick={() => setModalVisible(true)}>
          Add Distributor
        </Button>
      </div>

      <div style={{ overflow: 'auto', height: '70vh' }}>

        <Table dataSource={allDistributors} columns={columns} />
      </div>
    </div>
  )
}
