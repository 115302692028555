
export const actions = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_ALL_DISTRIBUTORS: "SET_ALL_DISTRIBUTORS",
  SET_CURRENT_DISTRIBUTOR: "SET_CURRENT_DISTRIBUTOR",
  SET_ALL_SUB_DISTRIBUTORS: "SET_ALL_SUB_DISTRIBUTORS",
  SET_CURRENT_SUB_DISTRIBUTOR: "SET_CURRENT_SUB_DISTRIBUTOR",
  SET_ALL_DEALERS: "SET_ALL_DEALERS",
  SET_CURRENT_DEALER: "SET_CURRENT_DEALER",
};

export const setUser = (data) => ({
  type: actions.SET_CURRENT_USER,
  data
})

export const setAllDistributors = (data) => ({
  type: actions.SET_ALL_DISTRIBUTORS,
  data
})

export const setCurrentDistributor = (data) => ({
  type: actions.SET_CURRENT_DISTRIBUTOR,
  data
})

export const setAllSubDistributors = (data) => ({
  type: actions.SET_ALL_SUB_DISTRIBUTORS,
  data
})

export const setCurrentSubDistributor = (data) => ({
  type: actions.SET_CURRENT_SUB_DISTRIBUTOR,
  data
})

export const setAllDealers = (data) => ({
  type: actions.SET_ALL_DEALERS,
  data
})

export const setCurrentDealer = (data) => ({
  type: actions.SET_CURRENT_DEALER,
  data
})

