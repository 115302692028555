
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import Dealers from './Dealer/Dealers';
import Distributors from './Distributors/Distributors';
import SubDistributors from './SubDistributor/SubDistributor';
import './Users.scss'

const TabPane = Tabs.TabPane


const Users = () => {
  const currentUser = useSelector(state => state.user.currentUser)

  return (
    <div>
      <div >
        <h2>Users</h2>
      </div>
      {currentUser ? <div>
        <Tabs
          defaultActiveKey="0"
        >
          {currentUser?.role === 'admin' ?
            <TabPane
              tab='Distributor'
              key="0">
              <Distributors />
            </TabPane>
            : null}
          {currentUser?.role === 'admin' || currentUser?.role === 'distributor' ?
            <TabPane
              tab="Sub-Distributors"
              key="1">
              <SubDistributors />
            </TabPane>
            : null}
          <TabPane
            tab="Dealers"
            key="2">
            <Dealers />
          </TabPane>
        </Tabs>
      </div> : null}
    </div>
  )
}

export default Users