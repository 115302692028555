import { DeleteOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Select, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../components/Search/Search';
import { setLoading } from '../../redux/actions/app.actions';
import { setCurrentPayment, setSimpleSearch } from '../../redux/actions/payments.actions';
import { createPayment, getAllPayments, getPaymentCsv, searchPayments, updatePayment } from '../../services/payments.services';
import convertToCsv from '../../utils/csvExport';
import PaymentModal from './PaymentModal/PaymentModal';

export default function SimplePayment() {
  const dispatch = useDispatch();



  const [modalVisible, setModalVisible] = useState(false)

  const currentUser = useSelector(state => state.user.currentUser)
  const payments = useSelector(state => state.payments.allSimpleActivities)
  const currentPayment = useSelector(state => state.payments.currentPayment)
  const configs = useSelector(state => state.app.appConfigs)
  const search = useSelector(state => state.payments.simpleSearch)
  const count = useSelector(state => state.payments.simpleCount)

  const getData = async () => {
    dispatch(setLoading(true))
    await dispatch(getAllPayments('simple'))
    dispatch(setLoading(false))
  }

  useEffect(() => {
    getData()
  }, []); //eslint-disable-line

  const onPageChange = async (pageNumber) => {
    dispatch(setLoading(true))
    dispatch(setSimpleSearch({ ...search, page: pageNumber, skip: (pageNumber - 1) * search.limit, limit: search.limit }))
    await dispatch(searchPayments('simple', search.search, (pageNumber - 1) * search.limit, search.limit))
    dispatch(setLoading(false))
  }

  const columns = [
    {
      title: 'Distributor',
      dataIndex: 'distributor',
      key: 'distributor',
      render: col => col?.name
    },
    {
      title: 'Sub Distributor',
      dataIndex: 'subDistributor',
      key: 'subDistributor',
      render: col => col?.name
    },
    {
      title: 'Dealer',
      dataIndex: 'dealer',
      key: 'dealer',
      render: col => col?.name
    },
    {
      title: 'Date Submitted',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: col => `${moment(col).tz('america/chicago').format('MM/DD/YYYY h:mm a')} (CT)`
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: txt => <span style={{ color: txt === 'pending' ? 'orange' : txt === 'completed' ? 'green' : txt === 'in-progress' ? 'blue' : 'red' }}>{txt}</span>
    },
    {
      title: 'Date Completed',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: col => `${moment(col).tz('america/chicago').format('MM/DD/YYYY h:mm a')} (CT)`
    },
    {
      title: 'Note',
      dataIndex: 'comment',
      key: 'comment',
      render: col => col ? col : '-'
    },
  ];
  if (currentUser?.role !== 'dealer') {
    columns.splice(6, 0, {

      title: 'Cost',
      dataIndex: 'plan',
      key: 'cost',
      render: (col) => col === '$25' ? '$19.22' : col === '$30' ? '$24.15' : col === '$40' ? '$25.30' : col === '$50' ? '$26.45' : '$26.45'
    })
    columns.splice(7, 0, {

      title: 'Profit',
      dataIndex: 'plan',
      key: 'profit',
      render: (col) => col === '$25' ? '$2.03' : col === '$30' ? '$1.35' : col === '$40' ? '$8.70' : col === '$50' ? '$16.05' : '$24.55'
    })
  }
  if (currentUser?.role === 'dealer') {
    columns.splice(10, 0, {
      title: 'Action',
      key: 'action',
      align: 'right',
      width: 180,
      render: (text, record) => (
        <span>
          <Button disabled={record.status !== 'pending'} type='primary' icon={<EditOutlined />} onClick={() => {
            dispatch(setCurrentPayment(record))
            setModalVisible(true)
          }} />
          <Divider type="vertical" />

          <Popconfirm disabled={!(record.status === 'pending' || record.status === 'in-progress')} placement='topRight' title="Are you sure to cancel this request?" onConfirm={() => {

            onUpdateHandler({ ...currentPayment, status: 'cancelled' })
          }}
          >
            <Button disabled={!(record.status === 'pending' || record.status === 'in-progress')} type='danger' icon={<DeleteOutlined />} onClick={() => dispatch(setCurrentPayment(record))} />
          </Popconfirm>
        </span>
      ),
    })
  }
  if (currentUser?.role === 'processor') {
    columns.splice(10, 0, {
      title: 'Action',
      key: 'action',
      align: 'right',
      width: 180,
      render: (text, record) => (
        <Button disabled={!(record.status === 'pending' || record.status === 'completed' || record.status === 'in-progress')} type='primary' onClick={() => {
          dispatch(setCurrentPayment(record))
          setModalVisible(true)
        }} >
          {record.status === 'completed' ? 'Refund' : 'Process'}
        </Button>
      )
    })
  }
  const onAddHandler = async (values) => {
    dispatch(setLoading(true))
    await dispatch(createPayment({ ...values, dealer: currentUser?._id }, payments))
    dispatch(setLoading(false))
  }

  const onUpdateHandler = async (values) => {
    dispatch(setLoading(true))
    await dispatch(updatePayment(currentPayment?._id, { ...values, dealer: currentPayment?.dealer._id }, payments))
    dispatch(setLoading(false))
  }

  const onSearch = async (values) => {
    let newData = {}
    for (const key in values) {
      if (values[key]) {
        if (key === 'date') {
          values[key] = [moment(values[key][0]).tz('america/chicago').startOf('day'), moment(values[key][1]).tz('america/chicago').endOf('day')]
        }
        newData = { ...newData, [key]: values[key] }
      }
    }
    dispatch(setSimpleSearch({
      ...search,
      skip: 0,
      searchText: '',
      type: '',
      page: 1,
      search: { ...newData }
    }))
    dispatch(setLoading(true))
    await dispatch(searchPayments('simple', newData, 0, search.limit))
    dispatch(setLoading(false))
  }

  const onClear = async (values) => {
    dispatch(setLoading(true))
    dispatch(setSimpleSearch({
      ...search,
      skip: 0,
      searchText: '',
      type: '',
      page: 1
    }))
    await dispatch(getAllPayments('simple', 0, true, search.limit))
    dispatch(setLoading(false))
  }

  const calculateSum = () => {
    let sum = 0
    for (let i = 0; i < payments.length; i++) {
      sum = sum + payments[i].amount
    }
    return sum
  }

  return (
    <div>
      <PaymentModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onAdd={onAddHandler}
        onUpdate={onUpdateHandler}
        currentPayment={currentPayment}
        setCurrentPayment={(val) => dispatch(setCurrentPayment(val))}
      />
      <div>
        {currentUser?.role === 'dealer' && !configs?.simpleStatus ? <p style={{ fontWeight: 'bold', fontSize: 16 }}>Currently system is under maintenances. Please try again later.</p> : null}
      </div>
      <Search onSearch={onSearch} onClear={onClear} sim={'simple'} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ marginBottom: 0, marginRight: 8, }}>Transaction per page</p>
          <Select defaultValue={25} onChange={async (value) => {
            dispatch(setLoading(true))
            dispatch(setSimpleSearch({ ...search, limit: value }))
            await dispatch(searchPayments('simple', search.search, search.skip, value))
            dispatch(setLoading(false))
          }}>
            <Select.Option label='25' value={25} >25</Select.Option>
            <Select.Option label='50' value={50} >50</Select.Option>
            <Select.Option label='100' value={100} >100</Select.Option>
          </Select>
          <div>
            <p style={{ margin: 0, marginLeft: 12 }}>Total Sum: <b>{calculateSum()}</b></p>
          </div>
          <div>
            <p style={{ margin: 0, marginLeft: 12 }}>Total Count: <b>{count}</b></p>
          </div>

        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip placement="topLeft" title={!configs?.simpleStatus || !configs?.processorAvailablity ? `Dear, Customer 
                Our business hours are
                Monday to Saturday 10am to 7pm (CST)
                Sunday 11am to 5pm (CST)

                Please submit your payment during business hours. 

                Thank you…`: null}>
            {currentUser?.role === 'dealer' ? <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
              <Button disabled={!configs?.simpleStatus || !configs?.processorAvailablity} style={{ marginRight: 12 }} type='primary' onClick={() => setModalVisible(true)}>
                Add Payment
              </Button>
            </div> : null}
          </Tooltip>

        </div>
      </div>
      <div style={{ height: '52vh', overflow: 'auto' }}>
        <Table dataSource={payments} columns={columns}
          pagination={{
            total: count,
            onChange: (page) => onPageChange(page),
            pageSize: search.limit,
            current: search.page,
            showSizeChanger: false,
            showQuickJumper: false
          }}
        />
      </div>
    </div >
  )
}
