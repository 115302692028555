import jsonexport from 'jsonexport';

// const convertToCsv = (data, filename) => {
//   jsonexport(data, (err, csv) => {
//     if (err) { return console.log(err); }
// var element = document.createElement('a');
// element.setAttribute('href', 'data:text/plain;charset=utf-8,' + csv);
// element.setAttribute('download', `${filename}`);
// element.style.display = 'none';
// document.body.appendChild(element);
// element.click();
// document.body.removeChild(element);
//   });
// }
const { Parser } = require('json2csv');
const convertToCsv = (data, filename) => {
  // console.log(data)
  try {
    const parser = new Parser(data);
    const csv = parser.parse(data);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + csv);
    element.setAttribute('download', `${filename}`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (err) {
    console.error(err);
  }
}
export default convertToCsv