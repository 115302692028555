import { Tabs } from 'antd';
import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import DealerActivities from './DealerActivities';
import DistributorActivities from './DistributorActivities';
import SubDistributorActivities from './SubDistributorActivities';
const TabPane = Tabs.TabPane

export default function PaymentActicities() {
  const currentUser = useSelector(state => state.user.currentUser)


  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'By',
      dataIndex: 'creator',
      key: 'creator',
      render: col => col?.name
    },
    {
      title: 'Date',
      dataIndex: 'cretedAt',
      key: 'cretedAt',
      render: col => `${moment(col).tz('america/chicago').format('DD-MM-YYYY hh:m a')} (CT)`
    },
  ];
  if (currentUser?.role === 'admin') {
    columns.splice(0, 0, {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: col => col?.name
    })
  }

  return (
    // <div>
    //   <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
    //   </div>
    //   <Table dataSource={dataSource} columns={columns} />
    // </div>
    <div>
      <div >
        <h2>Activities</h2>
      </div>
      <div>
        <Tabs
          defaultActiveKey="0"
        >
          {currentUser?.role !== 'subDistributor' && currentUser?.role !== 'dealer' ?
            < TabPane
              tab={currentUser?.role === 'distributor' ? 'Your Activities' : 'Distributors'}
              key="0">
              <DistributorActivities />
            </TabPane> : null}
          {currentUser?.role !== 'dealer' ?
            <TabPane
              tab={currentUser?.role === 'subDistributor' ? 'Your Activities' : 'Sub-Distributors'}
              key="1">
              <SubDistributorActivities />
            </TabPane> : null}
          <TabPane
            tab={currentUser?.role === 'dealer' ? 'Your Activities' : 'Dealers'}
            key="2">
            <DealerActivities />
          </TabPane>
        </Tabs>
      </div>
    </div >
  )
}
