import { Form, Button } from "antd"
import Modal from "antd/lib/modal/Modal"
import { useEffect, useRef, useState } from "react";
import { FormHiddenSubmitButton, FormInput, FormInputNumber, FormSelect } from "../../../../components/FormItems/FormItems"
import './SubDistributorModal.scss'
import proptypes from 'prop-types'
import { useSelector } from "react-redux";


const SubDistributorModal = ({ modalVisible, setModalVisible, currentSubDistributor, onAdd, onUpdate, onAmountUpdate, setCurrentSubDistributor }) => {


  const [form] = Form.useForm();
  const submitRef = useRef()

  const [selectedDistributor, setSelectedDistributor] = useState(null)

  const loggedUser = useSelector(state => state.user.currentUser)
  const distributors = useSelector(state => state.user.allDistributors)

  useEffect(() => {
    if (currentSubDistributor && distributors.length) {

      onDistributorChange(currentSubDistributor.distributor._id)

    } else if (loggedUser.role === 'distributor') {
      setSelectedDistributor(loggedUser)
    }
  }, [currentSubDistributor]); //eslint-disable-line


  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onClose = () => {
    setModalVisible(false)
    form.resetFields()
    setCurrentSubDistributor(null)
  }

  const distributorsOptions = () => {
    return distributors.map(o => ({
      label: o.name,
      value: o._id,
      amount: o.wallet.amount
    }))
  }

  const onDistributorChange = (value) => {
    console.log('useeffect', value)
    const distributor = distributors.find(d => d._id === value)
    if (distributor) {
      setSelectedDistributor(distributor)
    }
  }
  return (
    <Modal
      className='todo_modal'
      style={{ borderRadius: 8 }}
      bodyStyle={{ borderRadius: 8 }}
      destroyOnClose
      title={currentSubDistributor ? 'Update SubDistributor' : 'Add SubDistributor'}
      onOk={() => submitRef.current.click()}
      visible={modalVisible}
      onCancel={onClose}>
      <Form className='form' layout='vertical' form={form} preserve={false} onFinishFailed={onFinishFailed} onFinish={(values) => {
        console.log('ok')
        if (currentSubDistributor) {
          onUpdate(values)
          onClose()
        } else {
          onAdd(values)
          onClose()
        }
        setModalVisible(false)
      }}>
        <FormInput label='Name' name='name' required type='text' placeholder='Enter Name' initialValue={currentSubDistributor ? currentSubDistributor.name : ''} styles={null} />
        <FormInput disabled={currentSubDistributor} label='Username' name='username' required type='text' placeholder='Enter Username' initialValue={currentSubDistributor ? currentSubDistributor.username : ''} styles={null} />
        {currentSubDistributor ? null : <FormInput label='Password' name='password' required type='password' placeholder='Enter Password' styles={null} />}
        <FormInputNumber styles={{ width: '100%' }} maxlength={10} label='Phone' name='phone' required type='text' placeholder='Enter Phone' initialValue={currentSubDistributor ? currentSubDistributor.phone : null} />
        {loggedUser?.role === 'admin' ?
          <FormSelect search onChange={(value) => onDistributorChange(value)} label='Distributor' name='distributor' placeholder={'Select Distributor'} options={distributorsOptions()} required initialValue={currentSubDistributor ? currentSubDistributor.distributor._id : null} />
          : null}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormInputNumber label={`Amount ${currentSubDistributor ? ': ' + currentSubDistributor.wallet?.amount : ''}`} name='amount' required initialValue={0} max={selectedDistributor?.wallet.amount} />
          {currentSubDistributor ? <Button style={{ marginLeft: 12 }} onClick={
            () => {
              const data = form.getFieldsValue()
              onAmountUpdate(currentSubDistributor?._id, { amount: data.amount })
              onClose()
            }} type={'primary'}>Update Amount</Button> : null}
        </div>
        {selectedDistributor ? <p>Available Amount:{selectedDistributor.wallet.amount}</p> : currentSubDistributor ? currentSubDistributor.distributor.wallet.amount : null}
        <FormHiddenSubmitButton submitRef={submitRef} />
      </Form>
    </Modal >
  )
}

SubDistributorModal.propTypes = {
  modalVisible: proptypes.bool.isRequired,
  setModalVisible: proptypes.func.isRequired,
  onAdd: proptypes.func.isRequired,
  currentSubDistributor: proptypes.object,
  setCurrentSubDistributor: proptypes.func.isRequired,
  onUpdate: proptypes.func.isRequired,
  onDelete: proptypes.func.isRequired
};

export default SubDistributorModal
